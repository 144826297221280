/* JS do Sistema Gestor */
//paste this code under the head tag or in a separate js file.
// Wait for window load
$(window).on('load', function () {
    $(".se-pre-con").fadeOut();
});

$(function () {
    var url_gestor = $('meta[name=url]').attr("content");
    console.info('App Carregado em ' + url_gestor + '!');

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $(document).ajaxStop($.unblockUI);

    //$("body").addClass('mini-navbar');

    $("body").addClass('fixed-sidebar');

    $('.mascara_telefone').focusout(function () {
        var phone, element;
        element = $(this);
        phone = element.val().replace(/\D/g, '');
        if (phone.length == 8) {
            element.inputmask('9999-9999');
        } else {
            element.inputmask('99999-9999');
        }
        console.info(phone.length);
    }).trigger('focusout');

    $('.mascara_telefone_dd').focusout(function () {
        var phone, element;
        element = $(this);
        phone = element.val().replace(/\D/g, '');
        if (phone.length == 10) {
            element.inputmask('(99) 9999-9999');
        } else {
            element.inputmask('(99) 99999-9999');
        }
        console.info(phone.length);
    }).trigger('focusout');

    $('.mascara_telefone_dd_8').inputmask('(99) 9999-9999');
    $('.mascara_telefone_dd_9').inputmask('(99) 99999-9999');
    //$('.mascara_telefone_dd').inputmask('(99) 9999[9]-9999');

    $('.mascara_telefone_8').inputmask('9999-9999');
    $('.mascara_telefone_9').inputmask('99999-9999');
    //$('.mascara_telefone').inputmask('9999[9]-9999');

    $(".mascara_ddd").inputmask("99");
    $(".mascara_cpf").inputmask("999.999.999-99");
    $(".mascara_cep").inputmask("99999-999");
    $(".mascara_cnpj").inputmask("99.999.999/9999-99");
    $(".mascara_data").inputmask("99/99/9999");
    $(".mascara_inep_escola").inputmask("99999999");
    $(".mascara_inep_turma").inputmask("9999999999");
    $(".mascara_inep_pessoa").inputmask("999999999999");
    //$(".mascara_geolocalizacao").inputmask("9[9]-999999[9]");

    $(".mascara_moeda").maskMoney({symbol:"R$", showSymbol:false, decimal:",",thousands:"."/*, symbolStay: true*/, allowZero:true, allowNegative:true, defaultZero:true})

    $('.sidebar-collapse').slimScroll({
        height: '100%',
        railOpacity: 0.9
    });


    $('[data-toggle="tooltip"]').tooltip({
        template : '<div class="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-head"><h3><span class="glyphicon glyphicon-info-sign"></span> Ajuda</h3></div><div class="tooltip-inner"></div></div>',
        html: true
    });

    if ($.fn.datepicker) {

        !function(a){a.fn.datepicker.dates["pt-BR"]={days:["Domingo","Segunda","Terça","Quarta","Quinta","Sexta","Sábado"],daysShort:["Dom","Seg","Ter","Qua","Qui","Sex","Sáb"],daysMin:["Do","Se","Te","Qu","Qu","Se","Sa"],months:["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],monthsShort:["Jan","Fev","Mar","Abr","Mai","Jun","Jul","Ago","Set","Out","Nov","Dez"],today:"Hoje",monthsTitle:"Meses",clear:"Limpar",format:"dd/mm/yyyy"}}(jQuery);

        $('.datepicker-semanal').datepicker({
            language: "pt-BR",
            daysOfWeekDisabled: "0,6",
            calendarWeeks: true,
            autoclose: true,
            todayHighlight: true,
            /*datesDisabled: ['01/06/2017', '01/21/2017'],*/
            toggleActive: true
        });
    }


    /*var l = $('.ladda-button').ladda();

     l.click(function () {
     // Start loading
     l.ladda('start');

     // Do something in backend and then stop ladda
     // setTimeout() is only for demo purpose
     setTimeout(function () {
     l.ladda('stop');
     }, 2000)
     });*/


    // jQuery plugin to prevent double submission of forms
    jQuery.fn.preventDoubleSubmission = function () {

        $(this).on('submit', function (e) {
            console.info('submit');
            var $form = $(this);

            if ($form.data('submitted') === true) {
                showMessage("w", "Seu formulário já está em processamento!");
                // Previously submitted - don't submit again
                e.preventDefault();
            } else {
                // Mark it so that the next submit can be ignored
                $form.data('submitted', true);
            }
        });

        // Keep chainability
        return this;
    };

    //$('form').preventDoubleSubmission();


    $.getJSON(url_gestor + '/acesso-rapido', function (data) {
            var charMap = {
                "à": "a",
                "á": "a",
                "ã": "a",
                "â": "a",
                "é": "e",
                "è": "e",
                "ê": "e",
                "ë": "e",
                "É": "e",
                "ï": "i",
                "î": "i",
                "í": "i",
                "ì": "i",
                "ô": "o",
                "ò": "o",
                "ó": "o",
                "ö": "o",
                "õ": "o",
                "û": "u",
                "ù": "u",
                "ú": "u",
                "ü": "u",
                "ñ": "n",
                "ç": "c"
            };

            var normalize = function (input) {
                var inicio = input;
                $.each(charMap, function (unnormalizedChar, normalizedChar) {
                    var regex = new RegExp(unnormalizedChar, 'gi');
                    input = input.replace(regex, normalizedChar);
                });
                //console.log("normalize >> " + inicio + " - " + input);
                return input;
            };

            var queryTokenizer = function (q) {
                var normalized = normalize(q);
                return Bloodhound.tokenizers.whitespace(normalized);
            };

            var nombres = new Bloodhound({
                datumTokenizer: function (datum) {
                    //console.log("datumTokenizer >> " + datum.value + " - " + datum.displayValue);
                    return Bloodhound.tokenizers.whitespace(datum.value);
                },
                queryTokenizer: queryTokenizer,
                local: $.map(data, function (item) {
                    //console.log(item.name);
                    // Normalize the name - use this for searching
                    var normalized = normalize(item.name);
                    return {
                        value: normalized,
                        // Include the original name - use this for display purposes
                        displayValue: item.name,
                        url: item.url
                    };
                })
            });

            nombres.initialize();

            $('#top-search').typeahead({
                minLength: 4,
                hint: false,
                highlight: true
            }, {
                displayKey: 'displayValue',
                limit: 5,
                source: nombres.ttAdapter()
            }).bind("typeahead:selected", function (obj, datum, name) {
                //console.log(obj, datum, name);
                //console.log('Vou redirecionar para ' + datum.url);
                $(location).attr('href', datum.url);
            }).bind("typeahead:autocompleted", function (obj, datum, name) {
                //console.log(obj, datum, name);
                //console.log('Vou perguntar se ele quer acessar o módulo sugerido = ' + datum.url);
                swal({
                    title: "Deseja acessar a página sugerida abaixo?",
                    text: datum.url,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: false
                }, function () {
                    $(location).attr('href', datum.url);
                });
            });
        }
    );

});

function block() {

    $.blockUI(
        {
            message: 'Aguarde...',
            css: {
                border: 'none',
                padding: '15px',
                backgroundColor: '#000',
                '-webkit-border-radius': '10px',
                '-moz-border-radius': '10px',
                opacity: .5,
                color: '#fff',
                'font-size': '16px',
                'font-weight': 'bold'
            }
        }
    );
}

function showMessage(t, m, time) {

    if (isNaN(time))
        time = 5;

    time = time * 1000;

    var positionClass;
    if (t != 's' && t != 'i' && t != 'w' && t != 'e') {
        positionClass = 'toast-top-full-width';
        t = 'e';
        m = "MENSAGEM ERRADA";
    }
    else {
        positionClass = 'toast-top-right';
    }

    setTimeout(function () {
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showMethod: 'slideDown',
            positionClass: positionClass,
            timeOut: time
        };
        if (t == 's')
            toastr.success(m);
        else if (t == 'i')
            toastr.info(m);
        else if (t == 'w')
            toastr.warning(m);
        else if (t == 'e')
            toastr.error(m);
    }, 0);
}
